import { Form, Formik, FormikConfig, FormikProps, FormikValues, FormikHelpers } from 'formik';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import AutoSave from './AutoSave';
import Prompt from './Prompt';
import ErrorFocus from '@oberoninternal/travelbase-ds/components/form/ErrorFocus';

export type Handler<V> = (values: V, helpers: FormikHelpers<V>) => Promise<void> | void;

interface BaseProps<V> extends Omit<FormikConfig<V>, 'onSubmit'> {
    children: (props: FormikProps<V>) => React.ReactNode;
    handleAutoSave?: Handler<V>;
    skipPrompt?: boolean;
}

interface WithSubmitHandler<V> extends BaseProps<V> {
    handleSubmit: Handler<V>;
}

interface WithoutSubmitHandler<V> extends BaseProps<V> {
    handleAutoSave: Handler<V>;
}

export type FormedProps<V> = WithSubmitHandler<V> | WithoutSubmitHandler<V>;

const promptMessage = defineMessages({
    message: {
        defaultMessage: 'Je hebt de gegevens nog niet opgeslagen, weet je het zeker?',
    },
});

const createHandler =
    <V extends FormikValues>(handler: Handler<V>) =>
    async (values: V, helpers: FormikHelpers<V>) => {
        try {
            await handler(values, helpers);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
        }
    };

/* Formed requires either a `handleSubmit`, `handleAutosave` or both. */
const Formed = <V extends FormikValues>({ children, handleAutoSave, skipPrompt, ...rest }: FormedProps<V>) => {
    const { formatMessage } = useIntl();

    return (
        <Formik<V> {...rest} onSubmit={'handleSubmit' in rest ? createHandler(rest.handleSubmit) : () => {}}>
            {bag => (
                <Form>
                    <ErrorFocus />
                    {!skipPrompt && (
                        <Prompt message={formatMessage(promptMessage.message)} when={bag.dirty && !bag.isSubmitting} />
                    )}
                    {children(bag)}
                    {handleAutoSave && (
                        <AutoSave<V>
                            // we use useFormikContext in Autosave so this shouldn't be necessary... https://github.com/formium/formik/pull/2774
                            enableReinitialize={rest.enableReinitialize}
                            onSave={createHandler(handleAutoSave)}
                        />
                    )}
                </Form>
            )}
        </Formik>
    );
};

export default Formed;
