import { FormikHelpers, FormikValues, useFormikContext } from 'formik';
import isEqual from 'lodash.isequal';
import React, { useEffect } from 'react';
import usePrevious from '../hooks/usePrevious';

interface Props<V> {
    onSave: (values: V, helpers: FormikHelpers<V>) => Promise<void> | void;
    enableReinitialize?: boolean;
}

const AutoSave = <V extends FormikValues>({ onSave, enableReinitialize }: Props<V>) => {
    const { values, dirty, ...rest } = useFormikContext<V>();
    const previousValues = usePrevious(values);

    useEffect(() => {
        if (
            ((enableReinitialize && dirty) || !enableReinitialize) &&
            previousValues &&
            Object.keys(previousValues).length &&
            !isEqual(previousValues, values)
        ) {
            onSave(values, rest);
        }
    }, [dirty, enableReinitialize, onSave, previousValues, rest, values]);

    return <></>;
};

export default AutoSave;
