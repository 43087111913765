import RoundButton, { RoundButtonSize } from '@oberoninternal/travelbase-ds/components/action/RoundButton';
import ArrowLeft from '@oberoninternal/travelbase-ds/components/figure/ArrowLeft';
import ArrowRight from '@oberoninternal/travelbase-ds/components/figure/ArrowRight';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import { Box, BoxProps } from '@rebass/grid';
import React, { Children, FC, Fragment, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import ContentWrapper from '../ContentWrapper';
import { InView } from 'react-intersection-observer';

interface Props extends Omit<BoxProps, 'title'> {
    title?: ReactNode;
    continuous?: boolean;
    arrowsOnObject?: boolean;
    buttonSize?: RoundButtonSize;
}

const Carousel: FC<React.PropsWithChildren<Props>> = ({
    title,
    children,
    continuous = false,
    arrowsOnObject,
    buttonSize,
    ...props
}) => {
    const WrapperComponent = continuous ? Fragment : ContentWrapper;

    const viewport = useRef<HTMLDivElement | null>(null);
    const content = useRef<HTMLDivElement | null>(null);
    const [prevNextVisible, setPrevNextVisible] = useState(true);
    const scrollTo = useCallback(
        (scrollLeft?: number) => {
            const left = scrollLeft ?? viewport.current?.scrollLeft;
            viewport.current?.scrollTo({ left, behavior: 'smooth' });
        },
        [viewport]
    );
    const [prevEnabled, setPrevEnabled] = useState(false);
    const [nextEnabled, setNextEnabled] = useState(false);

    const calculateEnabled = useCallback(() => {
        const vpElement = viewport.current;

        if (vpElement) {
            setNextEnabled(Math.round(vpElement.scrollLeft + vpElement.clientWidth) !== vpElement.scrollWidth);
            setPrevEnabled(vpElement.scrollLeft > 0);
            setPrevNextVisible(vpElement.scrollWidth !== vpElement.clientWidth);
        }
    }, [viewport]);

    const count = useMemo(() => Children.count(children), [children]);

    useEffect(() => {
        calculateEnabled();
    }, [viewport, calculateEnabled, count]);

    return (
        <InView
            onChange={() => {
                calculateEnabled();
            }}
        >
            <Container {...props}>
                <HeaderWrapper hasTitle={!!title}>
                    {title && <Title variant="large">{title}</Title>}
                    {prevNextVisible ? (
                        <div style={{ flexShrink: 0 }}>
                            <LeftButton
                                variant="outline"
                                size={buttonSize ?? 'large'}
                                disabled={!prevEnabled}
                                arrowsOnObject={arrowsOnObject}
                                className={arrowsOnObject ? 'gt-s' : undefined}
                                onClick={() => {
                                    const firstItem = content.current?.firstElementChild;
                                    if (viewport?.current && firstItem) {
                                        scrollTo(viewport.current.scrollLeft - firstItem.scrollWidth);
                                        calculateEnabled();
                                    }
                                }}
                            >
                                <StyledArrowLeft disabled={!prevEnabled} />
                            </LeftButton>
                            <RightButton
                                variant="outline"
                                size={buttonSize ?? 'large'}
                                arrowsOnObject={arrowsOnObject}
                                className={arrowsOnObject ? 'gt-s' : undefined}
                                disabled={!nextEnabled}
                                onClick={() => {
                                    const firstItem = content.current?.firstElementChild;
                                    if (viewport?.current && firstItem) {
                                        scrollTo(viewport.current.scrollLeft + firstItem.scrollWidth);
                                        calculateEnabled();
                                    }
                                }}
                            >
                                <StyledArrowRight disabled={!nextEnabled} />
                            </RightButton>
                        </div>
                    ) : null}
                </HeaderWrapper>
                <WrapperComponent>
                    <ScrollContainer ref={viewport} onScroll={() => calculateEnabled()}>
                        <ScrollWrapper ref={content}>{children}</ScrollWrapper>
                    </ScrollContainer>
                </WrapperComponent>
            </Container>
        </InView>
    );
};

const Container = styled(Box)`
    display: flex;
    flex-direction: column;
`;

const HeaderWrapper = styled.div<{ hasTitle: boolean }>`
    display: flex;
    justify-content: ${({ hasTitle }) => (hasTitle ? 'space-between' : 'flex-end')};
    align-items: center;
    width: 100%;
    margin-bottom: 3.2rem;
`;

const ScrollContainer = styled.div`
    display: flex;
    margin: 0;
    margin-bottom: ${({ theme }) => theme.spacing['50_Semi']};
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    -ms-overflow-style: none;
    overscroll-behavior-x: none;
    will-change: transform;

    /* make it smooth on iOS */
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
        display: none;
    }
`;

const ScrollWrapper = styled.div`
    display: flex;

    & > *:first-child {
        box-sizing: content-box;
    }
`;

const LeftButton = styled(RoundButton)<{ disabled: boolean; arrowsOnObject?: boolean }>`
    margin-right: 0.8rem;

    opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
    ${({ arrowsOnObject }) =>
        arrowsOnObject &&
        css`
            position: absolute;
            z-index: 12;
            top: 50%;
            margin-top: -3.4rem;
            left: 2rem;

            && {
                background: white !important;
            }
        `}

    ${({ arrowsOnObject, disabled }) =>
        arrowsOnObject &&
        disabled &&
        `
            display: none
    `}
`;

const RightButton = styled(LeftButton)<{ disabled: boolean; arrowsOnObject?: boolean }>`
    margin-right: 0;
    left: unset;

    ${({ arrowsOnObject }) =>
        arrowsOnObject &&
        css`
            right: 2rem;
        `}
`;

const StyledArrowLeft = styled(ArrowLeft)<{ disabled: boolean }>`
    opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
`;

const StyledArrowRight = styled(ArrowRight)<{ disabled: boolean }>`
    opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
`;

const ChildContainer = styled(Box)`
    scroll-snap-align: start;

    & + & {
        margin-left: 3.2rem;
    }

    &:last-child {
        margin-right: 3.2rem;
    }
`;

const ChildWrapper = styled(Box)`
    width: 32rem;

    @media (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        width: 44.8rem;
    }
`;

export const CarouselChild = ({ children, ...props }: BoxProps) => (
    <ChildContainer {...props}>
        <ChildWrapper>{children}</ChildWrapper>
    </ChildContainer>
);

export default Carousel;
